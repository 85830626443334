<template>
	<div class="search_result">
		<div class="top">
			<div class="title" @click="backClick">
				{{$t('other.homePage')}}
			</div>
			<div class="img">
				<img src="../../../assets/home_icons/front.png">
			</div>
			<div class="sub_title">{{$t('title.hotRecommand')}}</div>
		</div>
		<div class="all_house">
			<houseItem2 v-for="(item,index) in recommendList" :key="index" :houseItem2="item"></houseItem2>
		</div>
		<div class="pagination">
			<el-pagination background layout="prev, pager, next,sizes" :total="total"
				@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
				:page-size="pageSize"  popper-class="select_bottom" :page-sizes="pageSizes"><!--:background="true"-->
			</el-pagination>
		</div>
		<img src="../../../assets/loading.gif" class="loadingImg" v-if="loadingValue==true">
	</div>
</template>

<script>
	import houseItem2 from '@/components/houseItem2.vue'

	export default {
		components: {
			houseItem2
		},
		data() {
			return {
				recommendList: [],
                backgrounds:true,
				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 16, //每页的条数
				pageSizes: [16],
				loadingValue:false,
				pagesType:'',
			}
		},
		
		mounted() {
			this.getRecommand();
		},
		// beforeRouteLeave(to, from, next){
			
		// 	if(this.currentPage>1){
		// 	//    --this.currentPage;
		// 	   console.log(--this.currentPage)
		// 	}
        //     if(this.pagesType==this.currentPage||this.currentPage==1||this.currentPage<1){
		// 		// next();
		// 		this.$router.go(1);
		// 		// console.log(from.name)
		// 		// return false;
		// 	}else{
		// 		console.log(this.currentPage)
		// 	}
		// },
		methods: {
			getRecommand() {
				this.loadingValue=true;
				// this.pagesType=this.$route.query.pages;
				this.$http.getRecommend({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					is_index: 0,
					type: 1,
					title: this.title,
				}).then(res => {
					this.loadingValue=false;
					window.scrollTo(0, 0)
					if (res.code == 1) {
						this.total = res.data.total;
						this.recommendList = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			backClick() {
				this.$router.go(-1);
			},
			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.getRecommand();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.getRecommand();
			},
		}
	}
</script>

<style scoped="" lang="less">
	.search_result {
		width: 1200px;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		display: flex;
		align-items: center;
		font-size: 14px;

		.title {
			color: #999999;
		}

		.title:hover {
			cursor: pointer;
		}

		.img {
			display: flex;
			align-items: center;
			margin: 0 8px;

			img {
				width: 8px;
				height: 8px;
			}
		}

		.sub_title {
			color: #333333;
		}
	}

	.all_house {
		margin-top: 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.pagination {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}
	// 新增的样式
	.loadingImg{
		width: 100rpx;
		height: 100rpx;
		position: fixed;
		top:50%;
		left: 50%;
		z-index: 11;
	}
</style>
